import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useEffect, useState, useMemo } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Button,
  Input,
  CardTitle,
} from "reactstrap"
import Select from "react-select"
import APIS from "../../apis"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import countryList from "react-select-country-list"
import cresusFond from "../../assets/images/cresuFond.jpg"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import axios from "axios";

const Register = props => {
  const [disbutReg, setDisbutReg] = useState(true)
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [numero, setNumero] = useState("")
  const [selectNationnalite, setSelectNationnalite] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [pays, setPays] = useState("")
  const [gouvernorat, setGouvernorat] = useState("")
  const [date, setDate] = useState("")
  const [grade, setGrade] = useState("")
  const [selectGrade, setSelectGrade] = useState("")
  const [gender, setGender] = useState(1)
  const [type, setType] = useState(1)
  const [etatCivil, setEtatCivil] = useState(1)
  const [file, setFile] = useState(null)
  const [departement, setDepartement] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")
  const [statut, setStatut] = useState([])
  const [selectStatut, setSelectStatut] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  const [password, setPassword] = useState("")
  const [verifPassword, setVerifPassword] = useState("")
  const [structure, setStructure] = useState("")
  const [display, setDisplay] = useState(0)
  const [annee, setAnnee] = useState("");
  useEffect(async () => {
    const res = await axios
        .get("https://scolarite.backcresus-institut.ovh/api/annee")
        .then(res => {
          const resAnnee = axios.post(
              "https://scolarite.backcresus-institut.ovh/api/annee/get_by_id",
              { id: res.data.Annee[0].CurrentAnnee[0].value }
          ).then(resAnnee=>{
            setAnnee(resAnnee.data.Annee.id)
          })
        })
    axios.get("https://scolarite.backcresus-institut.ovh/api/departement/select",{
      headers: {
        'X-Frame-Options' : "DENY",
        'annee':annee,
        'Content-Type': 'application/json',
      },
    }).then(resC => {
      setDepartement(resC.data.Departement)
    })
    axios.get("https://scolarite.backcresus-institut.ovh/api/grade/select",{
      headers: {
        'X-Frame-Options' : "DENY",
        'annee':annee,
        'Content-Type': 'application/json',
      },
    }).then(resG => {
      setGrade(resG.data.Grade)
    })
    axios.get("https://scolarite.backcresus-institut.ovh/api/statut/select",{
      headers: {
        'X-Frame-Options' : "DENY",
        'annee':annee,
        'Content-Type': 'application/json',
      },
    }).then(resS => {
      setStatut(resS.data.Statut)
    })
  }, [])

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const save = async () => {
    
    if (date != "") {
      let dateNaiss = date
      let month = "" + (dateNaiss.getMonth() + 1)
      let day = "" + dateNaiss.getDate()
      let year = dateNaiss.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    //Test si file vide
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    var grade_id = selectGrade.value || 0
    var departement_id=selectDepartement.value||0
    var statut_id=selectStatut.value||0
    var nationalite_id = selectNationnalite.label||0
    //alert(grade_id)
    if (nom == "" ) {
      toast.error("⛔ Nom obligatoire !! ", {
        containerId: "A",
      })
    } else if(prenom == "" ){
      toast.error("⛔ Prénom obligatoire !!", {
        containerId: "A",
      })
    } else if(numero == "" ){
      toast.error("⛔ Identifiant obligatoire !!", {
        containerId: "A",
      })
    } else if(tel == "" ){
      toast.error("⛔ Téléphone obligatoire !!", {
        containerId: "A",
      })
    } else if(grade_id == 0  ){
      toast.error("⛔ Grade obligatoire !!", {
        containerId: "A",
      })
    } else if(departement_id == 0 ){
      toast.error("⛔ Département obligatoire !!", {
        containerId: "A",
      })
    } else if( statut_id == 0 ){
      toast.error("⛔ Status obligatoire !!", {
        containerId: "A",
      })
    }else if( verifPassword == "" ){
      toast.error("⛔ Verifier password !!", {
        containerId: "A",
      })
    }else if (date != "") {
      if (password == verifPassword) {
        // const res = await APIS.post("enseignant/add", {
        setDisbutReg(false)
        axios.post("https://scolarite.backcresus-institut.ovh/api/enseignant/add",{
          firstName: nom,
          lastName: prenom,
          gender: gender,
          type: type,
          code: numero,
          nationality: nationalite_id,
          birthplace: lieuNaissance,
          email: email,
          telephone: tel,
          birthdate: convertDate,
          addressStreet: rue,
          addressZipCode: codePostal,
          addressCountry: pays,
          addressState: gouvernorat,
          civilState: etatCivil,
          grade_id: grade_id,
          departement_id: departement_id,
          statut_id: statut_id,
          fichier: pathFile,
          structure: structure,
          active: 0,
          password,
        },{
              headers: {
                'X-Frame-Options' : "DENY",
                'annee': annee,
                'Content-Type': 'application/json',
                 apipassword:"3KbmGc5tZhuDbPtb"
              },
            }).then(res => {
          if (res.data.status === 200) {
            if (pathFile != "") {
              const formData = new FormData()
              formData.append("document", file)
              formData.append("id", res.data.Enseignant.id)
              const resA = APIS.post(
                "enseignant/import_photo",
                formData,
                options,
                  {
                    headers: {
                      'X-Frame-Options' : "DENY",
                      'annee': annee,
                      'Content-Type': 'application/json',
                    },
                  }
              ).then(resA => {
                setDisplay(1)
                const timer = setTimeout(() => {
                  props.history.push("/login")
                }, 5000)
              })
            } else {
              setDisplay(1)
              const timer = setTimeout(() => {
                props.history.push("/login")
              }, 5000)
            }
          }
        })
      }else {
        toast.error("⛔ Verifier password !!", {
          containerId: "A",
        })
      }
    } else {
      toast.error("⛔ Date de naissance obligatoire !!", {
        containerId: "A",
      })
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${cresusFond})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "auto",
        width: "auto",
      }}
    >
      {display === 0 ? (
        <div style={{ paddingTop: "7%" }}>
          <Container>
            <Row className="justify-content-center">
              <Col md={12} lg={12} xl={12}>
                <Card className="overflow-hidden">
                  <div>
                    {" "}
                    <div>
                      <Row>
                        <Col className="justify-content-center pt-4">
                          <h1
                            style={{
                              color: "#485ec4",
                              textAlign: "center",
                            }}
                          >
                            Création d&apos;un compte enseignant
                          </h1>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-4">
                      <CardTitle
                        style={{ color: "#556ee6" }}
                        className="h4 mb-4 mt-4"
                      >
                        Informations générales :
                      </CardTitle>
                      <AvForm className="form-horizontal">
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Nom :
                              </Label>
                              <AvField
                                name="nom"
                                placeholder="Entrer nom"
                                type="text"
                                errorMessage="* nom obligatoire"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                onChange={e => setNom(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Prénom :
                              </Label>
                              <AvField
                                name="prenom"
                                placeholder="Entrer prénom"
                                type="text"
                                errorMessage="* prenom obligatoire"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                onChange={e => setPrenom(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="d-block mb-3">Sexe :</Label>
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline1"
                                  name="customRadioInline1"
                                  className="form-check-input"
                                  defaultChecked
                                  onClick={() => setGender(1)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline1"
                                >
                                  Masculin
                                </Label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline2"
                                  name="customRadioInline1"
                                  className="form-check-input"
                                  onClick={() => setGender(2)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline2"
                                >
                                  Feminin
                                </Label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Nationalité :
                              </Label>
                              <Select
                                options={options}
                                isSearchable={true}
                                onChange={e => setSelectNationnalite(e)}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="d-block mb-3">Type :</Label>
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline3"
                                  name="customRadioInline3"
                                  className="form-check-input"
                                  defaultChecked
                                  onClick={() => setType(1)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline3"
                                >
                                  CIN
                                </Label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline4"
                                  name="customRadioInline3"
                                  className="form-check-input"
                                  onClick={() => setType(2)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline4"
                                >
                                  Passeport
                                </Label>
                              </div>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Identifiant :
                              </Label>
                              <AvField
                                name="numero"
                                placeholder="Entrer identifiant"
                                type="number"
                                errorMessage="Min 8 chiffres"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  min: { value: 8 },
                                }}
                                onChange={e => setNumero(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Date de naissance :{" "}
                              </Label>
                              <Col md={12} className="pr-0">
                                <DatePicker
                                  className="form-control ddate"
                                  selected={date}
                                  onChange={setDate}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </Col>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Lieu de naissance :
                              </Label>
                              <Input
                                lg="3"
                                className="form-control"
                                placeholder="Entrer lieu de naissance"
                                type="text"
                                onChange={e => setLieuNaissance(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="d-block mb-3">
                                Etat Civil :
                              </Label>
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline5"
                                  name="customRadioInline5"
                                  className="form-check-input"
                                  checked={etatCivil === 1}
                                  onChange={e => setEtatCivil(1)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline5"
                                >
                                  Célibataire
                                </Label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline6"
                                  name="customRadioInline5"
                                  className="form-check-input"
                                  checked={etatCivil === 2}
                                  onChange={e => setEtatCivil(2)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline6"
                                >
                                  Marié(e)
                                </Label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  id="customRadioInline7"
                                  name="customRadioInline5"
                                  className="form-check-input"
                                  checked={etatCivil === 3}
                                  onChange={e => setEtatCivil(3)}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="customRadioInline7"
                                >
                                  Divorcé(e)
                                </Label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Photo :
                              </Label>
                              <Input
                                onChange={e => addFile(e)}
                                className="form-control"
                                type="file"
                                id="formFile"
                              />
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Adresse :
                        </CardTitle>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Rue :{" "}
                              </Label>
                              <Input
                                lg="3"
                                className="form-control"
                                placeholder="Entrer rue"
                                type="text"
                                onChange={e => setRue(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Code Postal :
                              </Label>
                              <Input
                                lg="3"
                                className="form-control"
                                placeholder="Entrer code postal"
                                type="number"
                                onChange={e => setCodePostal(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Pays :{" "}
                              </Label>
                              <Input
                                lg="3"
                                className="form-control"
                                placeholder="Entrer pays"
                                type="text"
                                onChange={e => setPays(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Gouvernorat :
                              </Label>
                              <Input
                                lg="3"
                                className="form-control"
                                placeholder="Entrer gouvernorat"
                                type="text"
                                onChange={e => setGouvernorat(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Téléphone :{" "}
                              </Label>
                              <AvField
                                name="tel"
                                placeholder="Entrer téléphone"
                                type="number"
                                errorMessage="* Téléphone obligatoire"
                                className="form-control"
                                validate={{
                                  required: { value: true },
                                  min: { value: 8 },
                                }}
                                onChange={e => setTel(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Informations professionnelles :
                        </CardTitle>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Grade :{" "}
                              </Label>
                              <Select
                                validate={{ required: { value: true } }}
                                isSearchable={true}
                                options={grade}
                                onChange={e => setSelectGrade(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Département :{" "}
                              </Label>
                              <Select
                                validate={{ required: { value: true } }}
                                isSearchable={true}
                                options={departement}
                                onChange={e => setSelectDepartement(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Statut :{" "}
                              </Label>
                              <Select
                                validate={{ required: { value: true } }}
                                isSearchable={true}
                                options={statut}
                                onChange={e => setSelectStatut(e)}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Structure de recherche (Unité de recherche ou
                                Laboratoire de recherche) :{" "}
                              </Label>
                              <Input
                                lg="3"
                                className="form-control"
                                placeholder="Entrer Structure de recherche"
                                type="text"
                                onChange={e => setStructure(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <CardTitle
                          style={{ color: "#556ee6" }}
                          className="h4 mb-4 mt-4"
                        >
                          Création Compte :
                        </CardTitle>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="E-Mail"
                                placeholder="Entrer un email valide"
                                type="email"
                                errorMessage="Email invalide"
                                onChange={e => setEmail(e.target.value)}
                                validate={{
                                  required: { value: true },
                                  email: { value: true },
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label>Password</Label>
                              <AvField
                                name="password"
                                type="password"
                                placeholder="Password"
                                onChange={e => setPassword(e.target.value)}
                                errorMessage="Enter password"
                                validate={{ required: { value: true } }}
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label>Verifier password</Label>
                              <AvField
                                name="password1"
                                type="password"
                                placeholder={"Réécrire password"}
                                onChange={e => setVerifPassword(e.target.value)}
                                errorMessage="Verifier le password"
                                validate={{
                                  required: { value: true },
                                  match: { value: "password" },
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Col
                            lg="12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="button"
                              className="btn btn-warning mb-2 me-2"
                              onClick={() => props.history.push("/login")}
                            >
                              Annuler
                            </Button>
                            <Button
                              id="Confirmer"
                              name="Confirmer"
                              type="button"
                              color="primary"
                              className="btn btn-primary mb-2 me-2"
                              disabled={!disbutReg}
                              onClick={save}
                            >
                              Confirmer
                            </Button>
                          </Col>
                        </div>
                      </AvForm>
                    </CardBody>
                    <div className="mt-1 text-center">
                      <p>
                        © {new Date().getFullYear()} Cresus. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger" /> by{" "}
                        <a href="https://cresus.pro/">cresus.pro</a>
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div style={{ padding: "19.2% 0%" }}>
          <Container>
            <div className="alert alert-success" role="alert">
              <h4 className="alert-heading">Succès !!</h4>
              <p className="mb-0">Cher enseignant,</p>
              <p className="mb-0">
                Nous avons bien reçu votre demande {"d'inscription"} et nous
                vous remercions de {"l’intérêt"} et du temps que vous nous
                témoignez. Votre compte sera activé par {"l'admin"} dans les
                plus brefs délais. Si vous avez la moindre question,{"l'équipe"}{" "}
                administrative demeure à votre disposition.
              </p>
              <p className="mb-0">Bonne journée.</p>
            </div>
          </Container>
        </div>
      )}
      <ToastContainer
        transition={Flip}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </div>
  )
}

export default withRouter(Register)

Register.propTypes = {
  history: PropTypes.object,
}
